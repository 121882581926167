.section {
    position: relative;
}

.full {
    position: relative;
    height: 100vh;
    width: 100%;
}

.inner {
    padding: 30px;
    height: 100%;
}

.bgAnimate {
    transition: background-color 0.5s ease;
}

.double {
    position: relative;
    height: 200vh;
    width: 100%;
}

.fit {
    position: relative;
    height: 100%;
    width: 100%;
}

.static {
    position: sticky;
    top: 93px;
}

.overflow {
    overflow: hidden;
}

.gutterM {
    margin-bottom: 30px;
}

.gutterL {
    margin-bottom: 50px;
}

.gutterXL {
    margin-bottom: 80px;
}

.grow {
    flex-grow: 1;
}

.bg {
    width: 100vw;
    height: 100vh;
    position: sticky;
    top: 0;
}

.viewContent {
    position: relative;
    z-index: 2;
}

/* INTRO BEGIN */

.intro {
    text-align: center;
    padding: 93px 0 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.introContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 30px;
    z-index: 2;
}

.introTitle {
    opacity: 0;
    transition: opacity 0.4s ease-in 1s;
}

.introDescription {
    opacity: 0;
    transition: opacity 0.5s ease-in 1.5s;
}

.introControls {
    opacity: 0;
    transition: opacity 0.5s ease-in 2s;
}

.introKongsWrapper {
    position: relative;
    transition: transform 0.4s ease;
}

.slider {
    width: 100%;
    height: 130px;
    margin-bottom: 30px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in 3s;
}

.sliderContainer {
    position: relative;
    width: auto;
    height: 130px;
    display: flex;
    align-items: flex-end;
    transform: translate(0, 0);
    animation: sliderAnimation 120s ease 3s;
}

.scrollDownIcon {
    cursor: pointer;
    animation: scrollDownAnimation 2s ease-in-out 3s infinite;
}

.animateIntro .introTitle,
.animateIntro .introControls,
.animateIntro .slider {
    opacity: 1;
}

.animateIntro .introDescription {
    opacity: 0.8;
}

.animateIntro .sliderContainer {
    transform: translate3d(0, 0, 0);
}

.animateIntro .sliderContainer:hover {
    animation-play-state: paused;
}

@keyframes scrollDownAnimation {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 10px, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes sliderAnimation {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-1000px, 0, 0);
    }
}

.sliderImg {
    margin: 0 6px;
    border: 1px solid #000000;
    border-radius: 20px;
    transition: transform 0.5s ease, margin 0.5s ease;
}

.sliderImg:hover {
    margin: 0 12px;
    transform: scale(1.24) translate(0px, -10px);
}

/* INTRO END */

.descriptionStaticContainer {
    position: relative;
    padding: 30px;
    width: 80%;
    z-index: 1;
}

.kongsWithRadius {
}

.kongsBg {
    position: absolute;
    background: url('../../public/images/kongs-bg.jpg') 0 0 repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 0;
    transition: border-radius 1.5s ease;
}

.kongsFade {
    position: absolute;
    background: linear-gradient(180deg, #000000, rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 0;
    transition: border-radius 1.5s ease;
}

.kongsMask {
    position: absolute;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 0;
    opacity: 1;
    transition: border-radius 1.5s ease;
}

.kongsContainer {
    position: relative;
    z-index: 3;
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.kongsContainerFixed {
    position: fixed;
    top: 91px;
    left: 0;
    width: 100%;
}

.kongsWithRadius .kongsMask,
.kongsWithRadius .kongsFade,
.kongsWithRadius .kongsBg {
    border-radius: 0 0 60px 60px;
}

.featureView {
    position: sticky;
    top: 93px;
}

.cardsView {
    position: sticky;
    top: 0;
}

.videoView {
    position: sticky;
    top: 93px;
}

.clubsView {
    position: sticky;
    top: 93px;
}

.featureViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 1500px;
    width: 100%;
}

.cardsViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 1200px;
    width: 100%;
}

.kongsViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 1400px;
    width: 100%;
}

.videoViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 2400px;
    width: 100%;
}

.featureViewImages {
    position: absolute;
    top: 300px;
    right: 350px;
}

.featureViewImages img {
    position: absolute;
}

.featureViewImages img:nth-child(1) {
    transform: rotate(-30deg);
}

.featureViewImages img:nth-child(2) {
    transform: rotate(17deg);
    top: 370px;
}

.featureViewImages img:nth-child(3) {
    transform: rotate(-28deg);
    top: 760px;
}

.btn {
    background: rgba(184, 122, 247, 0.2);
}

.btnInner {
    margin-right: 8px;
}

.clubsViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 2200px;
    width: 100%;
}

.slidesViewWrapper {
    position: relative;
    height: 100vh;
    min-height: 1600px;
    width: 100%;
    z-index: 4;
}

.listView {
    width: 100%;
    overflow: hidden;
    height: 235px;
    margin-bottom: 50px;
}

.listViewInner {
    width: auto;
    display: flex;
    align-items: center;
    height: 235px;
}

.listViewRow {
    position: relative;
    border-radius: 15px;
    padding: 24px;
    margin-right: 20px;
    height: 200px;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    border: 1px solid #333333;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.listViewRow img {
    transition: transform 0.5s ease;
}

.listViewRow:hover img {
    transform: scale(1.05);
}

.listViewImg {
    display: block;
}

.cardItem:last-child {
    margin-right: 0;
}

.cardItemDate {
    padding: 12px 0;
    border-top: 1px solid;
}

.videoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.VideoImg {
    width: 100%;
}

.videoFade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: opacity 0.5s ease;
}

.videoDescription {
    width: 80%;
    text-align: center;
}

.video {
    width: auto;
    height: auto;
}

.videoContentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.4s ease 0.2s;
}

.videoMainContent {
    position: relative;
    z-index: 3;
}

.videoAnimate .videoContentWrapper,
.videoAnimate .videoFade {
    opacity: 1;
}

.videoFadeBarFirst {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 1;
}

.videoFadeBarSecond {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #b87af7;
    z-index: 2;
}

@media screen and (max-height: 820px) {
    .introControls {
        margin-bottom: 40px;
    }
    .featureViewImages img {
        width: 206px;
        height: 350px;
    }
    .featureViewWrapper {
        min-height: 1100px;
    }
    .VideoImg {
        width: auto;
        height: 100%;
    }
    .videoMainContent {
        height: 100vh;
    }
}

@media screen and (max-height: 670px) {
    .introControls {
        margin-bottom: 18px;
    }
    .intro {
        padding-top: 120px;
    }
    .introDescription {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .featureViewImages img {
        width: auto;
        height: 300px;
    }
    .featureViewImages {
        top: 200px;
    }
    .featureViewWrapper {
        min-height: 1100px;
    }
    .VideoImg {
        width: auto;
        height: 100%;
    }
    .videoMainContent {
        height: 85vh;
    }
}

@media screen and (max-width: 576px) {
    .static {
        top: 87px;
    }
    .introTitle {
        max-width: 350px;
    }
    .introContainer {
        padding: 0;
    }
    .introDescription {
        padding: 0 20px;
    }
    .descriptionStaticContainer {
        padding: 20px;
        width: 100%;
        z-index: 1;
    }
    .featureView {
        position: relative;
        top: 0;
    }
    .featureViewWrapper {
        position: relative;
        height: 1100px;
        min-height: 1100px;
    }
    .featureViewImages {
        position: relative;
        margin: 40px auto 0;
        width: 224px;
        height: 380px;
        top: 430px;
        right: 0;
    }
    .featureViewImages img {
        position: absolute;
        top: 0;
        left: 0;
    }
    .featureViewImages img:nth-child(1) {
        transform: rotate(-15deg);
        top: 0;
    }

    .featureViewImages img:nth-child(2) {
        transform: rotate(15deg);
        top: 0;
    }
    .listView {
        margin-bottom: 10px;
    }
    .slidesViewWrapper {
        min-height: 800px;
    }
    .featureViewImages img:nth-child(3) {
        transform: rotate(0deg);
        top: 0;
    }
    .videoDescription {
        width: 100%;
        padding: 0 20px;
    }
    .clubsView {
        top: 87px;
    }
    .kongsBg {
        background-size: cover;
        background-position-x: center;
    }
}

@media screen and (max-height: 600px) {
    .listViewRow {
        height: 180px;
    }
}
