.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    overflow: hidden;
}

.container {
    position: relative;
    transform: scale(1);
    animation: pulsAnimation 2s infinite;
}

@keyframes pulsAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
