.layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.slide {
    display: block;
    width: auto;
}

.slideContainer {
    position: relative;
    z-index: 2;
    padding-left: 30px;
    min-height: 450px;
}

.slideImgContainer {
    position: absolute;
    width: 100%;
    top: 50%;
    right: 0;
    margin-top: -230px;
    z-index: 2;
}

.slideImages {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 452px;
}

.slideAnim {
    transition: transform 1s ease;
}

.slideImages img {
    margin-right: 20px;
}

.activeSlide {
    opacity: 1;
}

.slideImg {
    opacity: 0.15;
    cursor: pointer;
}

.slideImg:hover {
    opacity: 0.25;
}

.slideTitle {
    display: block;
    opacity: 0.5;
    text-align: left;
}

.slideDescription {
    display: block;
    text-align: left;
    margin-bottom: 32px;
    max-width: 100%;
    white-space: pre-line;
}

.slideText {
    width: 60%;
    font-size: 24px;
    line-height: 1.5;
}

.sliderNavigation {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 30px;
    display: flex;
    align-items: center;
}

.sliderNavigationBtn {
    cursor: pointer;
    margin-right: 16px;
}

.sliderNavigationBtn:last-child {
    margin-right: 32px;
}

.slideProgressContainer {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 40px;
    height: 10px;
}

.slideProgress {
    background: #000000;
    opacity: 0.33;
    width: 40px;
    height: 10px;
    border-radius: 20px;
}

.slideProgressFill {
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    height: 10px;
    border-radius: 20px;
}

.sliderFadeBarFirst {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #b87af7;
    z-index: 1;
}

.sliderFadeBarSecond {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #9bfcdf;
    z-index: 2;
}

.sliderFadeBarLast {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #b87af7;
    z-index: 3;
}

@media screen and (max-width: 820px) {
    .slideText {
        font-size: 22px;
    }
}

@media screen and (max-width: 576px) {
    .layout {
        display: block;
        padding: 120px 0 0;
    }
    .slide {
        display: block;
    }
    .activeSlide,
    .slideImg {
        width: auto;
        height: 270px;
    }
    .slideImages {
        top: 90px;
        height: 380px;
    }
    .slideDescription {
        max-width: 100%;
        margin-bottom: 12px;
    }
    .slideText {
        width: 100%;
        font-size: 20px;
    }
    .slideImgContainer {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 460px;
        bottom: 10px;
        top: auto;
    }
    .slideContainer {
        height: 180px;
    }
}

@media screen and (max-height: 680px) {
    .slideText {
        font-size: 16px;
    }
}

@media screen and (max-height: 650px) {
    .slideText {
        width: 90%;
    }
    .activeSlide,
    .slideImg {
        width: auto;
        height: 250px;
    }
    .slideImgContainer {
        bottom: -10px;
    }
}
